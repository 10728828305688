<template>
  <div class="popup-item" @click="open">
    <div class="d-flex align-items-center">
      <img :src="data.image" width="32" height="32" class="popup-item__img" alt="item">
      <div class="popup-item__content" v-html="data.html_markup" />
    </div>
    <img
      class="popup-item__arrow"
      src="@/assets/img/notify/arrow-right.svg"
      width="5"
      height="10"
      alt="next"
    >
    <a :href="data.target" rel="noopener" class="popup-item__btn" target="_blank">Погасить</a>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    open () {
      if (window.innerWidth < 992) {
        window.open(this.data.target, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.popup-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 11px;

  @media (max-width: 575px) {
    padding: 12px 8px 12px 10px;
  }

  &__img {
    border-radius: 50%;
    flex-shrink: 0;
  }

  &__arrow {
    @media (min-width: 576px) {
      display: none;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 8px;
    border: 1px solid #0061D9;
    border-radius: 8px;
    width: 102px;
    color: #0061D9;
    text-transform: uppercase;

    @media (max-width: 575px) {
      display: none;
    }
    &:hover, &.focus {
      background: #0061D9;
      color: white;
      text-decoration: none;
    }
  }

  &__content {
    display: inline-block;
    font-size: 13px;
    line-height: 100%;
    color: #010B23;
    font-weight: 400;
    margin: 0 12px;
    max-width: 212px;
  }
}
</style>
